<template>
    <div class="cbox">
        <div class="headbar">
            <div style="float: right;">
                <el-form :inline="true" :model="queryForm" style="">
                    <el-form-item label="标题" min-width="120">
                        <el-input size="small" v-model="queryForm.title" placeholder="请输入标题关键字"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="small" type="primary" @click="search">搜索</el-button>
                        <el-button size="small" type="primary" icon="el-icon-plus" @click="onAdd">新增
                        </el-button>

                    </el-form-item>
                </el-form>
            </div>
            <div style="padding-top: 14px;">
                <el-breadcrumb>
                    <el-breadcrumb-item>新手指导</el-breadcrumb-item>
                    <el-breadcrumb-item>新手指导</el-breadcrumb-item>

                </el-breadcrumb>
            </div>

        </div>
        <div class="">
            <el-table v-tableFit stripe :data="DataList" height="75vh" style="width: 100%;margin-bottom: 20px; color: #000;"
                border size="mini">
                <el-table-column prop="title" label="标题" min-width="230" />
                <el-table-column prop="content" label="内容" width="250" :show-overflow-tooltip="true" />

                <el-table-column prop="sort" label="排序" width="80" />

                <el-table-column prop="type" label="发布对象" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 0">全部</span>
                        <span v-if="scope.row.type == 1">学生</span>
                        <span v-if="scope.row.type == 2">教师</span>
                    </template>
                </el-table-column>


                <el-table-column fixed="right" label="操作" width="150">
                    <template slot-scope="scope">

                        <el-button type="text" @click="onModify(scope.row)">
                            编辑
                        </el-button>
                        <el-button type="text" style="color: #f56c6c;" @click="deleteItem(scope.row)">删除
                        </el-button>

                    </template>
                </el-table-column>
            </el-table>

            <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
                :current-page="Page.current_page" :page-size="Page.per_page" :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
                layout="total, prev, pager, next,sizes, jumper" :total="Page.count">
            </el-pagination>

        </div>



        <el-dialog title="新手指导" :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="true"
            @close="cloceDialog" width="800px">

            <el-form :model="EditItem" ref="EditItem" label-width="120px">

                <el-form-item label="问题">
                    <el-input type="textarea" v-model="EditItem.title" placeholder="标题">
                    </el-input>
                </el-form-item>


                <el-form-item label="发布对象">
                    <el-select size="small" v-model="EditItem.type">
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="学生" :value="1"></el-option>
                        <el-option label="教师" :value="2"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="排序" prop="sort">
                    <el-input-number :min="0" v-model.trim="EditItem.sort" placeholder="请输入排序号(数字)"
                        controls-position="right" />
                </el-form-item>
                <el-form-item label="答案">
                    <!-- <el-input type="textarea" v-model="EditItem.content" placeholder="内容">
                    </el-input> -->


                    <div style="line-height: initial; width: 100%; ">
                        <UEditor ref="ue" @ready="editorReady" style="width: 100%; "></UEditor>
                    </div>
                </el-form-item>


            </el-form>

            <div class="" slot="footer">
                <el-button size="small" type="primary" plain @click="cloceDialog">取消</el-button>
                <el-button size="small" type="primary" @click="saveForm">保存</el-button>

            </div>
        </el-dialog>


    </div>
</template>

<script>
import UEditor from '../notify/ueditor.vue'
export default {
    components: {
        UEditor,
    },
    data() {
        return {
            queryForm: {},
            DataList: [],
            Page: {
                current_page: 1,
                next_page: 2,
                per_page: 10,
                per_page: 10,
                total_page: 0,
                count: 0
            },

            EditItem: {
                type: 0,
                sort: 100,
            },

            dialogshow: false,
            editor: null,

        }
    },

    mounted() {
        this.search()
    },
    methods: {



        editorReady(editorInstance) {

            editorInstance.setContent(this.EditItem.content);
            editorInstance.addListener('contentChange', () => {
                this.EditItem.content = editorInstance.getContent();
            });
            this.editor = editorInstance;
        },

        query() {
            let params = this.queryForm
            params.page = this.Page.current_page
            params.pagesize = this.Page.per_page
            this.$http.post('/api/new_guidance_list', params).then(res => {
                this.DataList = res.data.data;
                this.Page = res.data.page
            });
        },


        handlePageChange(val) {
            this.Page.current_page = val;
            this.query()

        },
        handleSizeChange(val) {
            this.Page.per_page = val;
            this.query()

        },


        search: function () {
            this.query();
        },

        deleteItem(item) {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post("/api/new_guidance_delete", {
                    id: item.id
                }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    });

                    this.query();
                })

            }).catch(() => {

            });
        },


        onAdd() {
            this.dialogshow = true
            this.EditItem = {
                type: 0,
                sort: 100,
                content: ''
            }
        },
        onModify(item) {
            let _this = this
            this.dialogshow = true
            this.EditItem = item
            setTimeout(() => {
                _this.editor.setContent(item.content)
            }, 350)
        },

        cloceDialog() {
            this.dialogshow = false
        },

        saveForm() {
            this.EditItem.content = this.editor.getContent();
            this.$http.post("/api/new_guidance_edit", this.EditItem).then(res => {
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                this.dialogshow = false
                this.query();
            })
        },


    }
}
</script>
<style scoped type="text/less" lang="less">
.pageContainer {
    height: 100%;
}

.product-status {
    padding-bottom: 10px;
    border-bottom: 5px solid #e8e8e8;
}

.arttile {
    font-size: 18px;
    color: #333333;
    padding: 20px 0;
}

.typeitem {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0px 10px;
}

.contentcontent {
    padding: 20px 0;
}

.attaches {
    padding: 20px 0;
}

.attitem {
    color: #0785D1;
    cursor: pointer;
}

.useritem {
    width: 200px;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 5px;
    margin-right: 5px;
    border: 1px solid #f6f6f6;
    padding: 5px;
}

.useritem image {
    vertical-align: middle;
}
</style>
